// App.js
import { useState } from 'react';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import CourseList from './pages/CourseList';
import Login from './pages/Login';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (status) => {
    setIsLoggedIn(status);
  };

  return (
    <div className="App">
      <Header />
      {isLoggedIn ? <CourseList /> : <Login onLogin={handleLogin} />}
      <Footer />
    </div>
  );
}

export default App;
