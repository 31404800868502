// Modal.js
import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.css'; // Import any styling you have for the modal

const Modal = ({ isShowing, hide, card, isPhone }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,.5)', zIndex: 999,}}/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000,}}>
      <div className="modal" style={{width: isPhone ?  '90dvw': '50dvw', height: isPhone ? '500px' : '60dvh', backgroundColor: '#E9EBF1', padding: '30px', borderRadius: '6px', overflowY: 'scroll', boxSizing: 'border-box'}}>
        <div className="modal-header" style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
          <h2 style={{marginBottom: '0', width: isPhone ? '80dvw' : '40dvw'}}>{card.course_title}</h2>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide} style={{background: 'none', border: 'none', marginLeft: 'auto', cursor: 'pointer'}}>
            <span aria-hidden="true"><img src="/xmark.png" alt="xmark" style={{width: '17px', marginRight: '7px'}}/></span>
          </button>
        </div>
        <p>{card.university_name}</p>
        <div className="modal-content" style={{ margin: '30px 0px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
            <div style={{display: 'flex', height: '30px'}}><p><b>Course Type</b></p><p style={{marginLeft: 'auto'}}>{card.course_type}</p></div>
           {card.campus_city && <div style={{display: 'flex', height: '30px'}}><p><b>Campus City</b></p><p style={{marginLeft: 'auto'}}>{card.campus_city}</p></div> }
            <div style={{display: 'flex', height: '30px'}}><p><b>Location</b></p><p style={{marginLeft: 'auto'}}>{card.university_location}</p></div>
            <div style={{display: 'flex', height: '30px'}}><p><b>Duration</b></p><p style={{marginLeft: 'auto'}}>{card.duration}</p></div>
            {card.application_fee !='0.00' && <div style={{display: 'flex', height: '30px'}}><p><b>Application Fee</b></p><p style={{marginLeft: 'auto'}}>{ parseInt(card.application_fee) + ' ' +  card.currency}</p></div> }
           {card.global_ranking && <div style={{display: 'flex', height: '30px'}}><p><b>Global Ranking</b></p><p style={{marginLeft: 'auto'}}>{card.global_ranking}</p></div>}
            <p style={{ color: '#273593', fontWeight: 700, margin: '40px 0 0 auto', fontSize: '20px' }}>{parseInt(card.tuition_fee).toLocaleString()} {card.currency} / year</p>
        </div>
        <div>
            <p>{card.summary}</p>
        </div>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;
