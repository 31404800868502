// components/Login.js
import { useState } from 'react';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const handleLogin = () => {
    // Hardcoded credentials for testing
    const hardcodedUsername = 'ggadmin';
    const hardcodedPassword = 'GlobalGrads=24?';
    
    if (username === hardcodedUsername && password === hardcodedPassword) {
      onLogin(true);
    } else {
      alert('Invalid credentials');
    }
  };

    const input_style = {
        width: '100%',
        boxSizing: 'border-box',
        padding: '12px 40px 12px 10px', // Adjust padding to make room for the icon
        backgroundColor: '#E9EBF1',
        border: 'none',
        borderRadius: '5px',
        fontSize: '14px',
        marginTop: '5px'
    };

    const primary_btn_style = {
        backgroundColor: '#283593',
        border: 'none',
        borderRadius: '3px',
        padding: "6px 16px",
        color: 'white',
        fontWeight: '400',
        fontFamily: "Manrope, sans-serif",
        fontSize: '18px',
        cursor: 'pointer',
    };
    
    

  return (
    <div style={{width: '100%', height: '80dvh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{width: '350px', height: '450px', padding: '24px', border: '1px solid grey', borderRadius: '12px', boxSizing: 'border-box'}}>
        <img src="https://globalgrads.org/wp-content/uploads/2024/03/globalgrads-e1715069129923.png" alt="Site Logo" style={{width: '100px'}}/>
          <h2>Login</h2>
          <form  onSubmit={handleLogin} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
              <div>
                <label>
                  Username
                  <input type="text" style={input_style} value={username} onChange={(e) => setUsername(e.target.value)} />
                </label>
              </div>
              <div>
                <label>
                  Password
                  <input type="password" style={input_style} value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
              </div>
              <button type='submit' style={primary_btn_style}>Login</button>
          </form>
      </div>
    </div>
  );
}

export default Login;
