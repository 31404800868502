import React, { useState, useEffect } from 'react';
import './css/courses.css';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Modal from '../components/Modal';

const CourseList = () => {
    const [courses, setCourses] = useState([]);
    const [offset, setOffset] = useState(0);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedProgramLevels, setSelectedProgramLevels] = useState([]);
    const [selectedInstitutes, setSelectedInstitutes] = useState([]);
    const [coursesFound, setCoursesFound] = useState(0);
    const [isFilterExpand, setIsFilterExpand] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [minTuitionFee, setMinTuitionFee] = useState(445);
    const [maxTuitionFee, setMaxTuitionFee] = useState(523482);
    const [minFee, setMinFee] = useState(minTuitionFee);
    const [maxFee, setMaxFee] = useState(maxTuitionFee);
    const [coursesFetched, setCoursesFetched] = useState(false);
    const [dropdownState, setDropdownState] = useState({
        country: false,
        programLevel: false,
        university: false,
    });
    const [sortOption, setSortOption] = useState(''); // New state for sorting option

    const [countries, setCountries] = useState([]);
    const [programLevels, setProgramLevels] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [search, setSearch] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    
    const API_HOST = process.env.REACT_APP_API_HOST;

  const showModal = (card) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setSelectedCard(null);
    setIsModalOpen(false);
  };


    useEffect(() => {
        const checkScreenWidth = () => {
            if (window.innerWidth < 768) {
                setIsPhone(true);
            } else {
                setIsPhone(false);
            }
        };

        checkScreenWidth();
        fetchCountries();
        fetchProgramLevels();

        window.addEventListener('resize', checkScreenWidth);

        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    useEffect(() => {
        fetchCourses(0);
    }, [selectedCountries, selectedProgramLevels, selectedInstitutes, minFee, maxFee, search, sortOption]);

    useEffect(() => {
        if (selectedCountries.length > 0 && selectedProgramLevels.length > 0) {
            fetchInstitutes();
        }
        else{
            setInstitutes([]);
        }
    }, [selectedCountries, selectedProgramLevels]);

    const fetchCourses = async (offset) => {
        try {
            const params = new URLSearchParams({
                offset,
                tuition_fee_min: minFee,
                tuition_fee_max: maxFee,
                search,
            });

            selectedCountries.forEach(country => params.append('country[]', country));
            selectedProgramLevels.forEach(level => params.append('course_type[]', level));
            selectedInstitutes.forEach(institute => params.append('university_name[]', institute));
            if (sortOption) {
                params.append('sort_by', sortOption); // Add sorting parameter
            }

            const response = await fetch(`${API_HOST}wp-json/custom-csv-importer/v1/data?${params.toString()}`);
            const responseData = await response.json();
            const { total_rows, data } = responseData;

            setCoursesFound(total_rows);
            setCourses(prevCourses => offset === 0 ? data : [...prevCourses, ...data]);
            setCoursesFetched(true);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    const fetchInstitutes = async () => {
        try {
            const params = new URLSearchParams();
            selectedCountries.forEach(country => params.append('country[]', country));
            selectedProgramLevels.forEach(level => params.append('course_type[]', level));

            const response = await fetch(`${API_HOST}wp-json/custom-csv-importer/v1/universities?${params.toString()}`);
            const universities = await response.json();
            setInstitutes(universities);
        } catch (error) {
            console.error("Error fetching universities: ", error);
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await fetch(`${API_HOST}wp-json/custom-csv-importer/v1/countries`);
            const countries = await response.json();
            setCountries(countries);
        } catch (error) {
            console.error("Error fetching countries: ", error);
        }
    };

    const fetchProgramLevels = async () => {
        try {
            const response = await fetch(`${API_HOST}wp-json/custom-csv-importer/v1/course-types`);
            const levels = await response.json();
            setProgramLevels(levels);
        } catch (error) {
            console.error("Error fetching program levels: ", error);
        }
    };

    const primary_btn_style = {
        backgroundColor: '#E9EBF1',
        border: 'none',
        borderRadius: '5px',
        padding: "8px 12px",
        color: '#000000',
        fontWeight: '600',
        fontFamily: "Manrope, sans-serif",
        fontSize: '20px',
        cursor: 'pointer',
    };

    const handleInputChange = (event) => {
        setSearch(event.target.value);
    };

    const toggleFilter = () => {
        setIsFilterExpand(!isFilterExpand);
    };

    const closeFilter = () => {
        setIsFilterExpand(false);
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    
    const handleRangeMinChange = (event) => {
        const value = Number(event.target.value);
        setMinFee(value);
        setSliderValues([value, maxFee]);
    }

    const handleRangeMaxChange = (event) => {
        const value = Number(event.target.value);
        setMaxFee(value);
        setSliderValues([minFee, value]);
    }

    const handleSliderChange = (value) => {
        const [minValue, maxValue] = value;
        setMinFee(minValue);
        setMaxFee(maxValue);
    };

    const [sliderValues, setSliderValues] = useState([minFee, maxFee]);

    useEffect(() => {
        setSliderValues([minFee, maxFee]);
    }, [minFee, maxFee]);

    const handleDropdownToggle = (dropdownName) => {
        setDropdownState(prevState => ({
            ...prevState,
            [dropdownName]: !prevState[dropdownName],
        }));
    };

    const handleCheckboxChange = (event, checkboxType) => {
        const isChecked = event.target.checked;
        const checkboxName = event.target.id;

        if (checkboxType === 'country') {
            if (isChecked) {
                setSelectedCountries(prevSelected => [...prevSelected, checkboxName]);
            } else {
                setSelectedCountries(prevSelected =>
                    prevSelected.filter(item => item !== checkboxName)
                );
            }
        } else if (checkboxType === 'programLevel') {
            if (isChecked) {
                setSelectedProgramLevels(prevSelected => [...prevSelected, checkboxName]);
            } else {
                setSelectedProgramLevels(prevSelected =>
                    prevSelected.filter(item => item !== checkboxName)
                );
            }
        } else if (checkboxType === 'institute') {
            if (isChecked) {
                setSelectedInstitutes(prevSelected => [...prevSelected, checkboxName]);
            } else {
                setSelectedInstitutes(prevSelected =>
                    prevSelected.filter(item => item !== checkboxName)
                );
            }
        }
    };

    const handleLoadMore = () => {
        const newOffset = offset + 10;
        setOffset(newOffset);
        fetchCourses(newOffset);
    };
    const handleSortOptionChange = (option) => {
        setSortOption(option);
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };

    
    const removeFilter = (type, filter) => {
        if(type === 'country'){

            setSelectedCountries(prevSelected =>
                prevSelected.filter(item => item !== filter)
            );
        }
        else if(type === 'programLevel'){
            setSelectedProgramLevels(prevSelected =>
                prevSelected.filter(item => item !== filter)
            );
        }
        else if(type === 'institute'){
            setSelectedInstitutes(prevSelected =>
                prevSelected.filter(item => item !== filter)
            );

        }
    }

    const dropdown_styling = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white', 
        padding: '7px 10px', 
        borderRadius: '5px 5px 0px 0px',
        fontSize: '14px', 
        cursor: 'pointer'
    };


    const checkbox_container_styling = {
        margin: '2px 0px', 
        backgroundColor: 'white', 
        padding: '6px', 
        fontSize: '14px', 
        fontWeight: '400',
    };

    return (
        <div style={{ display: 'flex', justifyContent: isPhone ? 'initial' : 'center', overflow: isFilterExpand ? isPhone ?  'hidden' : '' : ''}}>
            {/* Filter sidebar */}
            <CSSTransition
                in={isFilterExpand}
                timeout={300}
                classNames="sidebar"
                unmountOnExit
            >
                <div style={{
                    backgroundColor: '#E9EBF1',
                    height: isPhone ? '' : '100dvh',
                    minWidth: isPhone ? '100dvw' : '400px',
                    maxWidth: isPhone ? '100%' : '0',
                    position: 'sticky',
                    overflow: 'scroll',
                    top: 0,
                }}>
                    <div style={{padding: '22px 16px'}}>
                        <div style={{display: 'flex', fontSize: '20px', alignItems: 'center'}}>
                            <div style={{fontWeight: '600'}}>Filters</div>
                            <div onClick={closeFilter} style={{marginLeft: 'auto', cursor: 'pointer'}}><img src="/xmark.png" alt="xmark" style={{width: '17px', marginRight: '7px'}}/></div>
                        </div>
                        {/* divder */}
                        <div style={{width: '100%', height: '1px', backgroundColor: 'rgba(95, 115, 165, .4)', margin: '16px 0px'}}></div>
                        <div style={{display: 'flex', flexDirection: 'column', rowGap: '10px'}}>
                             {/* Country DropDown */}
                             <div>
                                <div onClick={() => handleDropdownToggle('country')} style={dropdown_styling}>
                                    <div style={{fontWeight: '500'}}>Countries</div>
                                    <div style={{marginLeft: 'auto'}}><FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></div>
                                </div>
                                {countries.length > 0 &&
                                <CSSTransition
                                    in={dropdownState.country}
                                    timeout={300}
                                    classNames="dropdown"
                                    unmountOnExit
                                >
                                    <div style={{overflow: 'scroll', maxHeight: '100dvh'}}>
                                    {countries.map((country, index) => (
                                            <div key={index} style={checkbox_container_styling} className='checkbox-container'>
                                                <input
                                                    type="checkbox"
                                                    id={country}
                                                    name={country}
                                                    onChange={(e) => handleCheckboxChange(e, 'country')}
                                                    checked={selectedCountries.includes(country)}
                                                />
                                                <label htmlFor={country}>{country}</label>
                                            </div>
                                    ))}
                                    </div>
                                </CSSTransition>
                                 }
                            </div>
                            {/* Program level DropDown */}
                            <div>
                                <div onClick={() => handleDropdownToggle('programLevel')} style={dropdown_styling}>
                                    <div style={{fontWeight: '500'}}>Program Levels</div>
                                    <div style={{marginLeft: 'auto'}}><FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></div>
                                </div>
                                {programLevels.length > 0 && 
                                <CSSTransition
                                    in={dropdownState.programLevel}
                                    timeout={300}
                                    classNames="dropdown"
                                    unmountOnExit
                                >
                                    <div style={{overflow: 'scroll', maxHeight: '100dvh'}}>
                                    {programLevels.map((programLevel, index) => (
                                            <div key={index} style={checkbox_container_styling} className='checkbox-container'>
                                                 <input
                                                    type="checkbox"
                                                    id={programLevel}
                                                    name={programLevel}
                                                    onChange={(e) => handleCheckboxChange(e, 'programLevel')}
                                                    checked={selectedProgramLevels.includes(programLevel)}
                                                />
                                                <label htmlFor={programLevel}>{programLevel}</label>
                                            </div>
                                    ))}
                                    </div>
                                </CSSTransition>
                                }
                            </div>
                            {/* University DropDown */}
                            {institutes.length > 0 &&
                            <div>
                                <div onClick={() => handleDropdownToggle('university')} style={dropdown_styling}>
                                    <div style={{fontWeight: '500'}}>Institutes</div>
                                    <div style={{marginLeft: 'auto'}}><FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></div>
                                </div>
                                
                                <CSSTransition
                                    in={dropdownState.university}
                                    timeout={300}
                                    classNames="dropdown"
                                    unmountOnExit
                                >
                                   
                                    <div style={{overflow: 'scroll', maxHeight: '100dvh'}}>
                                        {/* Universities checkboxes */}
                                        { institutes.map((institute, index) => (
                                            <div key={index} style={checkbox_container_styling} className='checkbox-container'>
                                                 <input
                                                    type="checkbox"
                                                    id={institute}
                                                    name={institute}
                                                    onChange={(e) => handleCheckboxChange(e, 'institute')}
                                                    checked={selectedInstitutes.includes(institute)
                                                    
                                                    }
                                                />
                                                <label htmlFor={institute}>{institute}</label>
                                            </div>
                                    ))}
                                    </div>
                                    
                                </CSSTransition>
                                
                                </div>
                                }
                        </div>
                        
                        {/* divder */}
                        <div style={{width: '100%', height: '1px', backgroundColor: 'rgba(95, 115, 165, .4)', margin: '16px 0px'}}></div>
                        <div>
                            <p>Tuition Fee</p>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <input style={{width: '114px', height: '32px', borderRadius: '5px', border: 'none', padding: '7px 10px'}} type="number" value={minFee} min={minTuitionFee} max={maxTuitionFee} onInput={handleRangeMinChange}/>
                                <input style={{width: '114px', height: '32px', borderRadius: '5px', border: 'none', padding: '7px 10px'}} type="number" value={maxFee} onInput={handleRangeMaxChange}/>
                            </div>
                            <RangeSlider
                                className={'fee-slider'}
                                min={minTuitionFee} 
                                max={maxTuitionFee} 
                                step={1} 
                                value={sliderValues}
                                onInput={handleSliderChange}
                            />
                        </div>
                    </div>
                </div>
            </CSSTransition>
            {/* Main Section */}
            <div style={{display: 'flex', width: isPhone ? '' : coursesFetched ? '1440px' : '100%',}}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <div style={{ marginTop: '20px', padding: '5px 20px 5px 20px', boxSizing: 'border-box', position: 'sticky', top: '0', backgroundColor: 'white' }}>
                    <div style={{ position: 'relative', margin: '20px 0' }}>
                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder='Search Courses, Universities...'
                        style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '15px 40px 15px 10px', // Adjust padding to make room for the icon
                backgroundColor: '#E9EBF1',
                border: 'none',
                borderRadius: '5px',
                fontSize: '16px',
                        }} 
                        value={search}
                        onChange={handleInputChange}
                    />
                    <img 
                        src="/search.png" // Replace with the actual path to your search icon
                        alt="search icon"
                        style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '20px', // Adjust the size as needed
                height: '20px', // Adjust the size as needed
                pointerEvents: 'none', // Ensure the icon doesn't interfere with input actions
                        }}
                    />
                </div>
                    <div style={{ display: 'flex' }}>
                            <div>
                                <button
                                    style={primary_btn_style} onClick={toggleFilter}><img src="/filter.png" alt="filter" style={{width: '17px', marginRight: '7px'}}/>Filters</button>
                            </div>
                            <div style={{ marginLeft: 'auto', position: 'relative' }}>
                                <button style={primary_btn_style} onClick={toggleDropdown}>
                                    <img src="/sort.png" alt="sort" style={{ width: '17px', marginRight: '7px' }} />Sort
                                </button>
                                <div className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`} style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '110%',
                                    backgroundColor: '#E9EBF1',
                                    boxShadow: '0 4px 5px rgba(0,0,0,0.2)',
                                    borderRadius: '5px',
                                    zIndex: 100,
                                    overflow: 'hidden',
                                    width: '220px'
                                }}>
                                    <ul style={{ listStyleType: 'none', padding: '0px', margin: 0, fontSize: '14px', fontWeight: '500' }}>
                                        <li onClick={() => handleSortOptionChange('tuition_fee_asc')} style={{ padding: '10px 20px', cursor: 'pointer' }}>Tuition Fee - Low to High</li>
                                        <li onClick={() => handleSortOptionChange('tuition_fee_desc')} style={{ padding: '10px 20px', cursor: 'pointer' }}>Tuition Fee - High to Low</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap', width: '100%', margin:  '20px 0px', overflowY: 'scroll'}}>
                            {/* Active Filters */}
                            {selectedCountries.map((activeFilter, index) => (
                            <div key={index} style={{backgroundColor: '#E9EBF1', padding: '5px 6px', borderRadius: '8px', whiteSpace: 'nowrap', height: '20px', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                                <img onClick={() => removeFilter('country', activeFilter)} src="/xmark.png" alt="xmark" style={{marginRight: '7px', verticalAlign: 'center', cursor: 'pointer'}}/>
                                {activeFilter}
                            </div>
                            ))}
                            {selectedInstitutes.map((activeFilter, index) => (
                            <div key={index} style={{backgroundColor: '#E9EBF1', padding: '5px 6px', borderRadius: '8px', whiteSpace: 'nowrap', height: '20px', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                                <img onClick={() => removeFilter('institute', activeFilter)} src="/xmark.png" alt="xmark" style={{marginRight: '7px', verticalAlign: 'center', cursor: 'pointer'}}/>
                                {activeFilter}
                            </div>
                            ))}
                            {selectedProgramLevels.map((activeFilter, index) => (
                            <div key={index} style={{backgroundColor: '#E9EBF1', padding: '5px 6px', borderRadius: '8px', whiteSpace: 'nowrap', height: '20px', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                                <img onClick={() => removeFilter('programLevel', activeFilter)} src="/xmark.png" alt="xmark" style={{marginRight: '7px', verticalAlign: 'center', cursor: 'pointer'}}/>
                                {activeFilter}
                            </div>
                            ))}
                        </div>
                    </div>
                
                    <div style={{ display: 'flex', marginTop: '5px' }}>
                        <p style={{ marginLeft: 'auto', color: '#273593', fontWeight: '600', fontSize: '20px', marginRight: '30px' }}>
                            Courses Found: {coursesFound}
                        </p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', padding: '0 20px'}}>
                        {/* cards container */}
                        <div style={{ display: 'flex', margin: '0 auto', flexWrap: 'wrap', gap: '10px', justifyContent: isPhone ? 'center' : 'initial',}}>
                            {courses.map((course, index) => (
                                <div 
                                key={index} 
                                id={course.id} 
                                className="card" 
                                onClick={() => showModal(course)}
                                style={{
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    backgroundColor: '#E9EBF1',
                                     padding: '16px', 
                                     borderRadius: '5px',
                                     width: '310px', 
                                     height: '250px', 
                                     cursor: 'pointer',
                                     transition: 'height .4s ease-in-out',
                                     }}>
                                    <div style={{height: '100px'}}>
                                        <h3 style={{
                                            margin: '0',
                                            fontSize: '20px',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                            {course.course_title}
                                        </h3>
                                        <p style={{
                                            fontWeight: '700', color: '#4E4E4E', margin: '5px 0 20px 0', display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>{course.university_name}</p>
                                    </div>
                                    <div className="course-details" style={{ display: 'flex', flexDirection: 'column', rowGap: '5px', marginBottom: '20px'}}>
                                        <span style={{ fontWeight: '700', color: '#4E4E4E', display: 'flex', }}><p style={{ margin: 0 }}>Location:</p><p style={{ margin: '0 0 0 auto' }}>{course.campus_city ? course.campus_city + "," : ''} {course.country}</p></span>
                                        <span style={{ fontWeight: '700', color: '#4E4E4E', display: 'flex', }}><p style={{ margin: 0 }}>Duration:</p><p style={{ margin: '0 0 0 auto' }}>{course.duration}</p></span>
                                        <span style={{ fontWeight: '700', color: '#4E4E4E', display: 'flex', }}><p style={{ margin: 0 }}>Application Fee:</p><p style={{ margin: '0 0 0 auto' }}>{course.application_fee !== '0.00' ? parseInt(course.application_fee) + ' ' +  course.currency : 'N/A'}</p></span>
                                    </div>
                                    <p style={{ color: '#273593', fontWeight: 700, margin: 'auto 0 0 auto', fontSize: '20px' }}>{parseInt(course.tuition_fee).toLocaleString()} {course.currency} / year</p>

                                </div>
                            ))}
                        </div>
                        {selectedCard && (
                        <Modal 
                        isShowing={isModalOpen}
                        hide={hideModal}
                        card={selectedCard}
                        isPhone={isPhone}
                        />
                            )}
                        {/* Load More button Container */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '50px',
                            marginBottom: '100px'
                        }}>
                            <button onClick={handleLoadMore}
                                className='load-more-button'
                                style={{
                                    color: '#273593',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    fontWeight: '500',
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                }}
                            >
                                Load More
                                <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronDown} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseList;
