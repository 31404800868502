// Header.js
import React, { useState } from 'react';
import './Header.css'; // Import the CSS file for styling
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const [dropdownState, setDropdownState] = useState(false);

    const toggleDropdown = () => {
        setDropdownState(!dropdownState);
    };

  return (
    <>
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <a href="https://globalgrads.org">
            <img src="https://globalgrads.org/wp-content/uploads/2024/03/globalgrads-e1715069129923.png" alt="Site Logo" />
          </a>
        </div>
        <nav className="main-menu lg-display" style={{margin: '0 auto'}}>
          <ul>
            <li><a href="https://globalgrads.org">HOME</a></li>
            <li><a href="https://globalgrads.org/about-us/">ABOUT US</a></li>
            <li><a href="https://globalgrads.org/blog/">BLOG</a></li>
            <li><a href="https://globalgrads.org/contact">CONTACT</a></li>
            <li><a href="https://globalgrads.org/book-free-consultation/">BOOK CONSULTATION</a></li>
          </ul>
        </nav>
        <div className='phone-menu-toggle' style={{marginLeft: 'auto', fontSize: '30px'}} onClick={toggleDropdown} >
        <FontAwesomeIcon icon={faBars} />

        </div>
        <div className="book-button">
          <a href="https://globalgrads.org/australian-immigration-seminar/">Book Seminar Slot</a>
        </div>
      </div>
    </header>
     <CSSTransition
     in={dropdownState}
     timeout={300}
     classNames="dropdown"
     unmountOnExit
     >
     <nav className="main-menu sm-display">
       <ul>
         <li><a href="https://globalgrads.org">HOME</a></li>
         <li><a href="https://globalgrads.org/about-us/">ABOUT US</a></li>
         <li><a href="https://globalgrads.org/blog/">BLOG</a></li>
         <li><a href="https://globalgrads.org/contact">CONTACT</a></li>
         <li><a href="https://globalgrads.org/book-free-consultation/">BOOK CONSULTATION</a></li>
       </ul>
     </nav>
     </CSSTransition>
     </>
  );
};

export default Header;
