// Footer.js
import React from 'react';
import './Footer.css'; // Import the CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <img style={{width: '150px'}} src="https://globalgrads.org/wp-content/uploads/2024/05/GG-logo-white.png" alt="Global Grads Logo" />
          <h4>Imagine A Better Future</h4>
          <p>Global Grads - Your Trusted Partner in Navigating the World of International Education and Building Your Pathway to Success</p>
        </div>
        <div className="footer-column" style={{color: 'white'}}>
          <h4>Get In Touch</h4>
          <ul style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <li>2nd Floor, 298 Q, Commercial Block, DHA Phase 2 Lahore, Pakistan</li>
            <li><a href="tel:03115999398">Front Desk: 0311 5999398</a></li>
            <li><a href="tel:+924235895711">Landline: +924235895711</a></li>
          </ul>
        </div>
        {/* <div className="footer-column">
          <h4>Updates</h4>
          <div className="post">
            <h5>Post Title 1</h5>
            <p>Excerpt for post 1...</p>
          </div>
          <div className="post">
            <h5>Post Title 2</h5>
            <p>Excerpt for post 2...</p>
          </div>
        </div> */}
      </div>
      <div className="footer-bottom">
        <p>© 2024 GlobalGrads. All Rights Reserved. Website by <a href="https://octtoppus.com">Octtoppus</a></p>
      </div>
    </footer>
  );
};

export default Footer;
